import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import clsx from "clsx";
import Typography from "components/base/Typography";
import IconButton from "aether/IconButton";
import { ReactComponent as DotsVertical } from "assets/dots-vertical.svg";
import { ReactComponent as EditIcon } from "assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/delete-icon.svg";
import { useAssistantActionMenuStyles as useStyles } from "../styles";
import EditAssistant from "./EditAssistant";
import { type IAssistant } from "pages/Assistant/types";
import DeleteAssistant from "./DeleteAssistant";

interface Props {
  assistant: IAssistant;
  onUpdateAssistant: (updatedAssistant: IAssistant) => void;
  onRemoveAssistant: (removeAssistant: IAssistant) => void;
}

const AssistantActionMenu: React.FC<Props> = ({
  assistant,
  onUpdateAssistant,
  onRemoveAssistant,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openAction, setOpenAction] = useState<{
    edit: boolean;
    delete: boolean;
  }>({
    edit: false,
    delete: false,
  });

  const isMenuOpen = Boolean(anchorEl);

  const menuOptions = [
    {
      key: "edit-assistant",
      title: "Edit",
      icon: EditIcon,
      onClick: (event: any) => {
        setOpenAction({ ...openAction, edit: !openAction.edit });
      },
    },
    {
      key: "delete-assistant",
      title: "Delete",
      icon: DeleteIcon,
      onClick: (event: any) => {
        setOpenAction({ ...openAction, delete: !openAction.delete });
      },
    },
  ];

  return (
    <div className={classes.assistantActionMenuContainer}>
      <IconButton
        size="xsmall"
        variant="outlined"
        color="secondary"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <DotsVertical />
      </IconButton>

      <Menu
        id={"assistant-action-menu"}
        className={classes.assistantActionDropdownContainer}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menuOptions.map(
          ({ key, title, icon: ItemIcon, onClick: onItemClick }, index) => (
            <MenuItem
              className={clsx("list-item-container", key)}
              key={key}
              onClick={(event) => {
                setAnchorEl(null);
                onItemClick(event);
              }}
            >
              <ItemIcon />
              <Typography className="list-item-title">{title}</Typography>
            </MenuItem>
          )
        )}
      </Menu>

      <EditAssistant
        open={openAction.edit}
        assistant={assistant}
        onClose={() => {
          setOpenAction((prevState) => ({ ...prevState, edit: false }));
        }}
        onEditSuccessCb={(data) => {
          onUpdateAssistant({ ...assistant, ...data });
        }}
      />
      <DeleteAssistant
        open={openAction.delete}
        assistant={assistant}
        onClose={() => {
          setOpenAction((prevState) => ({ ...prevState, delete: false }));
        }}
        onDeleteSuccessCb={(data) => {
          onRemoveAssistant(data);
        }}
      />
    </div>
  );
};

export default AssistantActionMenu;
