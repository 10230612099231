import { ReactComponent as BasicSettingsIcon } from "assets/message-text-square.svg";
import { ReactComponent as ChatWidgetSettingsIcon } from "assets/brush-icon.svg";
import { ReactComponent as ChatCollapsedSettingsIcon } from "assets/cursor-box.svg";
import BasicSettings from "./BasicSettings";
import AccordionCard, {
  type AccordionCardProps,
} from "components/base/AccordionCard";
import ChatWidgetSettings from "./ChatWidgetSettings";
import ChatCollapsedSettings from "./ChatCollapsedSettings";
import { Formik, Form } from "formik";
import { AppearanceSettingsFields } from "pages/Assistant/types";
import { useSelector } from "store";
import { useAppearanceStyles as useStyles } from "./styles";
import { AppearanceValidationSchema } from "./config";

const SettingConfigurations: AccordionCardProps[] = [
  {
    title: "Basic",
    subtitle: "Botname, Sub heading, Welcome message & overview message",
    icon: <BasicSettingsIcon />,
    content: <BasicSettings />,
    disabled: false,
  },
  {
    title: "Chat widget",
    subtitle: "Avatar, Logo, brand color",
    icon: <ChatWidgetSettingsIcon />,
    content: <ChatWidgetSettings />,
    disabled: false,
  },
  {
    title: "Chat collapsed state",
    subtitle: "Nudge message, Closed state appearance, ",
    icon: <ChatCollapsedSettingsIcon />,
    content: <ChatCollapsedSettings />,
    disabled: true,
  },
];

const AppearanceSettings: React.FC = () => {
  return (
    <Form>
      <div className="content-wrapper">
        {SettingConfigurations.map((configuration, index) => {
          if (configuration?.disabled) return null;
          return <AccordionCard key={index} {...configuration} />;
        })}
      </div>
    </Form>
  );
};

const Appearance: React.FC = () => {
  const classes = useStyles();
  const { settings } = useSelector((state) => state.bot);
  const { chatbot_settings } = settings ?? {};

  const initialValues = {
    [AppearanceSettingsFields.bot_display_name]:
      chatbot_settings?.bot_display_name ?? "",
    [AppearanceSettingsFields.header_message]:
      chatbot_settings?.header_message ?? "",
    [AppearanceSettingsFields.welcome_message]:
      chatbot_settings?.welcome_message ?? "",
    [AppearanceSettingsFields.input_box_placeholder]:
      chatbot_settings?.input_box_placeholder ?? "",
    [AppearanceSettingsFields.header_logo_enabled]:
      chatbot_settings?.header_logo_enabled ?? "",
    [AppearanceSettingsFields.header_logo_url]:
      chatbot_settings?.header_logo_url ?? "",
    [AppearanceSettingsFields.header_accent_color]:
      chatbot_settings?.header_accent_color ?? "",
    [AppearanceSettingsFields.header_text_color]:
      chatbot_settings?.header_text_color ?? "",
    [AppearanceSettingsFields.chat_bubble_enabled]:
      chatbot_settings?.chat_bubble_enabled ?? "",
    [AppearanceSettingsFields.chat_bubble_url]:
      chatbot_settings?.chat_bubble_url ?? "",
    [AppearanceSettingsFields.display_nudge]:
      chatbot_settings?.display_nudge ?? "",
    [AppearanceSettingsFields.nudge_message]:
      chatbot_settings?.nudge_message ?? "",
    [AppearanceSettingsFields.default_widget_state]:
      chatbot_settings?.default_widget_state ?? "",
  };

  return (
    <div className={classes["appearance-container"]}>
      <Formik
        initialValues={initialValues}
        validationSchema={AppearanceValidationSchema}
        onSubmit={() => {}}
      >
        <AppearanceSettings />
      </Formik>
    </div>
  );
};

export default Appearance;
