import Chip from "aether/Chip";
import { useAssistantCardStyles as useStyles } from "../styles";
import { type IAssistant } from "pages/Assistant/types";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import Button, { ButtonSizes } from "aether/Button";
import AssistantActionMenu from "./AssistantActionMenu";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";
import { formatToHumanStringFromDateTime } from "utils/dateUtils";

interface Props {
  data: IAssistant;
  setRef: any;
  onUpdateAssistant: (updatedAssistant: IAssistant) => void;
  onRemoveAssistant: (removeAssistant: IAssistant) => void;
}

const AssistantCard: React.FC<Props> = ({
  data,
  setRef,
  onUpdateAssistant,
  onRemoveAssistant,
}) => {
  const classes = useStyles();
  const { gotoAssistant } = useRouteNavigator();

  return (
    <div ref={setRef} className={classes.assistantCardContainer}>
      <div className="card-header-container">
        <div className="assistant-meta-container">
          <Typography
            renderInLines={1}
            variant={TypographyVariants.textXL}
            weight={TypographyWeights.bold}
          >
            {data?.name}
          </Typography>
          <Typography weight={TypographyWeights.medium} renderInLines={2}>
            {data?.description}
          </Typography>
        </div>
        {data?.status && <Chip label={data?.status} />}
      </div>
      <div className="card-action-container">
        <Typography weight={TypographyWeights.semiBold}>
          Last edited{" "}
          {formatToHumanStringFromDateTime(
            new Date(data?.modified),
            // "MMM d yyyy"
            "dd-MMM-yyyy hh:mm aa"
          )}
        </Typography>

        <div className="flex col-gap-16">
          <Button
            size={ButtonSizes.xsmall}
            onClick={() => {
              gotoAssistant(data._id);
            }}
          >
            View details
          </Button>
          {/* <Button size={ButtonSizes.xsmall} variant={ButtonVariants.outlined}>
            Go to live
          </Button> */}
          <AssistantActionMenu
            assistant={data}
            onUpdateAssistant={onUpdateAssistant}
            onRemoveAssistant={onRemoveAssistant}
          />
        </div>
      </div>
    </div>
  );
};

export default AssistantCard;
