import { SettingsActions } from "store/reduxActions/botActions";
import { createReducer } from "../util";

const initialState = {
  isLoading: false,
  isError: false,
  _id: "",
  assistant_id: null,
  channel_settings: {
    enabled_channels: [],
  },
  chatbot_settings: {
    bot_display_name: "",
    enabled_channels: [],
    header_message: "",
    welcome_message: "",
    input_box_placeholder: "",
    bot_response_length: "",
    show_source_of_info: "",
    request_feedback: "",
    display_nudge: "",
    nudge_message: "",
    default_widget_state: "",
    no_bot_response: "",
    status_banner: "",
    end_conversation_msg: "",
    end_user_type: "",
    accent_color: "",
    suggestions: [],
  },
  voice_settings: {
    voice_id: "",
    language: "",
    associated_phone_number: "",
  },
  created: "",
  modified: "",
};

const botSettingsSlice = createReducer(initialState, {
  [SettingsActions.FETCH_BOT_SETTINGS_LOADING]: (state: any, action: any) => {
    return {
      ...state,
      isLoading: true,
      isError: false,
    };
  },
  [SettingsActions.FETCH_BOT_SETTINGS_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false,
    };
  },
  [SettingsActions.FETCH_BOT_SETTINGS_ERROR]: (state: any, action: any) => {
    return {
      ...state,
      isLoading: false,
      isError: true,
    };
  },
  [SettingsActions.UPDATE_BOT_SETTINGS_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false,
    };
  },
  [SettingsActions.UPDATE_CHATBOT_SETTINGS]: (state: any, action: any) => {
    const newChatbotSettings = Object.assign(
      {},
      state.chatbot_settings,
      action.payload
    );

    return {
      ...state,
      ...state.settings,
      chatbot_settings: newChatbotSettings,
      isLoading: false,
    };
  },
  [SettingsActions.UPDATE_CHATBOT_SETTINGS_FIELD]: (
    state: any,
    action: any
  ) => {
    const { key, value } = action.payload;

    return {
      ...state,
      ...state.settings,
      chatbot_settings: {
        ...state.chatbot_settings,
        [key]: value,
      },
      isLoading: false,
    };
  },
  [SettingsActions.UPDATE_BOT_SETTING_PROPERTY]: (state: any, action: any) => {
    const { key, value } = action.payload;
    return {
      ...state,
      [key]: value,
      isLoading: false,
    };
  },
});

export default botSettingsSlice;
