import { makeStyles } from "@mui/styles";
import Typography from "components/base/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import { type ISource } from "types/Source";
import { useCallback, useState } from "react";
import { formatToHumanStringFromDate } from "utils/dateUtils";
import SourceDelete from "./SourceDelete";
import SourceVisibility from "./SourceVisibility";
// import SourceRefresh from "./SourceRefresh";
import SourceUpload from "./SourceUpload";
import { ReactComponent as EmptyState } from "assets/empty-state.svg";
import { ReactComponent as LinkIcon } from "assets/link-icon.svg";
import FileTypeIcon from "components/shared/FileTypeIcon";
import { CircularProgress } from "@mui/material";
// import { SourceActions } from "store/reduxActions/botActions";
import { getKnowledgeSources } from "api/knowledge-base/source";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import {
  FileResource,
  LinkResource,
  ResourceType,
} from "pages/KnowledgeBase/types";
import SourceStatusChip from "components/KnowledgeSource/SourceStatus";

const useStyles = makeStyles((theme) => ({
  "source-list-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    padding: "24px",
    gap: "16px",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",

    "& .source-table-container": {
      "& .table-body": {
        "& .title-cell": {
          height: "76px",

          "& .source-type-icon": {
            width: "24px",
            height: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
            background: "var(--inactive-surfaces-subtle, #F9F8F8)",
            boxShadow:
              "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
          },
          "& .title": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 1,
            "-webkit-box-orient": "vertical",
          },
        },
      },
    },

    "& .source-empty-container": {
      height: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      "& svg": {
        height: "100%",
      },
    },

    "& .source-loader-container": {
      height: "28vh",
    },
  },
  sourcelabel: {
    weight: 600,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

interface IColumn {
  id: "name" | "dateUpdated" | "status" | "visibility" | "rowAction";
  label: string;
  maxWidth?: string;
  width?: string;
  alignment?: "center" | "right" | "left";
}

const columns: readonly IColumn[] = [
  {
    id: "name",
    label: "Source",
    maxWidth: "40%",
    width: "40%",
    alignment: "left",
  },
  {
    id: "dateUpdated",
    label: "Date uploaded",
    maxWidth: "20%",
    width: "20%",
    alignment: "center",
  },
  {
    id: "status",
    label: "Status",
    maxWidth: "15%",
    width: "15%",
    alignment: "center",
  },
  {
    id: "visibility",
    label: "Visibility",
    maxWidth: "10%",
    width: "10%",
    alignment: "center",
  },
  {
    id: "rowAction",
    label: "",
    maxWidth: "15%",
    width: "15%",
    alignment: "right",
  },
];

const SourceList: React.FC = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [parentNode, setParentNode] = useState<HTMLDivElement | null>();
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>();
  const [pageState, setPageState] = useState({ empty: false, error: false });

  const onDataFetched = ({
    count,
    results,
  }: {
    count: number;
    results: Array<FileResource | LinkResource>;
  }): void => {
    if (!count) {
      setPageState({ empty: true, error: false });
    }

    setPageState({ empty: false, error: false });
    setLoading(false);
  };

  const onError = (err: any): void => {
    console.error(err);

    setPageState({ empty: false, error: true });
    setLoading(false);
  };

  const {
    loading: loadSpinner,
    fetchedData: data,
    setFetchedData,
    nextUrl,
  } = useInfiniteScroll({
    parentNode,
    lastElement,
    apiConfig: {
      getData: getKnowledgeSources,
    },
    beforeStartFetchingData: () => {},
    onDataFetched,
    onError,
    filters: {},
  });

  const createDate = useCallback((created: string) => {
    const date = new Date(created);

    return formatToHumanStringFromDate(date);
  }, []);

  const handleOpenLink = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  const handleOnChangeVisibility = useCallback(
    (id: string, enabled: boolean) => {
      setFetchedData((prevData: any) => {
        const updatedData = prevData.map((item: any) =>
          item.id === id ? { ...item, enabled } : item
        );
        return updatedData;
      });
    },
    [setFetchedData]
  );

  const handleOnDeleteCb = useCallback(
    (id: string) => {
      setFetchedData((prevData: any) => {
        const updatedData = prevData.filter((item: any) => item.id !== id);
        return updatedData;
      });
    },
    [setFetchedData]
  );

  if (loading) {
    return (
      <div className={classes["source-list-container"]}>
        <div className="source-loader-container center">
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (pageState?.error) {
    return (
      <div className={classes["source-list-container"]}>
        <div className="source-empty-container">
          <Typography>Something went wrong</Typography>
        </div>
      </div>
    );
  }

  if (pageState?.empty) {
    return (
      <div className={classes["source-list-container"]}>
        <div className="source-empty-container">
          <EmptyState />
        </div>
      </div>
    );
  }

  return (
    <div className={classes["source-list-container"]}>
      <div className="source-table-header">
        <Typography variant="textXl" className="w-semi-bold">
          Sources
        </Typography>
      </div>

      <div
        ref={(ref) => {
          setParentNode(ref);
        }}
        className="source-table-container"
      >
        <TableContainer>
          <Table>
            <TableHead className="table-head">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      maxWidth: column.maxWidth,
                      width: column.width,
                    }}
                    align={column.alignment}
                  >
                    <Typography variant="textSm" className="w-bold">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className="table-body">
              {data?.map((row: FileResource | LinkResource, index: number) => {
                return (
                  <TableRow
                    ref={
                      index === data?.length - 1 && nextUrl
                        ? setLastElement
                        : null
                    }
                    hover
                    tabIndex={-1}
                    key={`${row.id}-${index}`}
                  >
                    <TableCell
                      className="title-cell flex align-items-center col-gap-8"
                      align={columns[0].alignment}
                    >
                      {row.resourceType === ResourceType.FILE && (
                        <>
                          <div className="source-type-icon">
                            <FileTypeIcon
                              type={(row as FileResource).file_type}
                            />
                          </div>
                          <Typography className="title w-medium">
                            {(row as FileResource).title}
                          </Typography>
                        </>
                      )}
                      {row.resourceType === ResourceType.LINK && (
                        <>
                          <LinkIcon />
                          <Typography
                            className={classes.sourcelabel}
                            onClick={() => {
                              handleOpenLink((row as LinkResource).url);
                            }}
                          >
                            {(row as LinkResource).name}
                          </Typography>
                        </>
                      )}
                    </TableCell>

                    <TableCell align={columns[1].alignment}>
                      <Typography variant="textSm" className="w-semi-bold">
                        {createDate(row.created)}
                      </Typography>
                    </TableCell>

                    <TableCell align={columns[2].alignment}>
                      {row.resourceType === ResourceType.FILE && (
                        <SourceStatusChip
                          status={(row as FileResource).pending_sync}
                        />
                      )}
                      {row.resourceType === ResourceType.LINK && (
                        <SourceStatusChip
                          status={(row as LinkResource).render_status}
                        />
                      )}
                    </TableCell>

                    <TableCell align={columns[3].alignment}>
                      {row.resourceType === ResourceType.FILE && (
                        <SourceVisibility
                          id={row.id}
                          action={row.resourceType}
                          enabled={(row as FileResource).active}
                          onChangeVisibility={handleOnChangeVisibility}
                        />
                      )}
                      {row.resourceType === ResourceType.LINK && (
                        <SourceVisibility
                          id={row.id}
                          action={row.resourceType}
                          enabled={(row as LinkResource).enabled}
                          onChangeVisibility={handleOnChangeVisibility}
                        />
                      )}
                    </TableCell>

                    <TableCell align={columns[4].alignment}>
                      <div className="flex align-items-center col-gap-16">
                        <SourceUpload
                          id={row.id}
                          name={(row as FileResource).title}
                        />
                        <SourceDelete
                          action={row.resourceType}
                          id={row.id}
                          onClose={() => {}}
                          onDeleteCb={handleOnDeleteCb}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {nextUrl && loadSpinner && (
            <div className="center mt-2 mb-20">
              <CircularProgress size={28} />
            </div>
          )}
        </TableContainer>
      </div>
    </div>
  );
};

export default SourceList;
