import { isNil } from "lodash";
import { useNavigate } from "react-router-dom";

const useAPIIntegrationsRouteNavigator = (): {
  gotoAPIIntegrationListPage: (botId: string) => void;
  gotoAPI: (botId: string, apiId: string) => void;
} => {
  const navigate = useNavigate();

  const gotoAPIIntegrationListPage = (botId: string): void => {
    if (isNil(botId)) {
      console.error("BotId is not present");
      return;
    }
    navigate(`/assistant/${botId}/tools/apis`);
  };

  const gotoAPI = (botId: string, apiId: string): void => {
    if (isNil(botId) || isNil(apiId)) {
      console.error("BotId or APIId is not present");
      return;
    }
    navigate(`/assistant/${botId}/tools/apis/editor/${apiId}`);
  };

  return {
    gotoAPIIntegrationListPage,
    gotoAPI,
  };
};

export default useAPIIntegrationsRouteNavigator;
