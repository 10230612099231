import { useCallback, useState } from "react";
import clsx from "clsx";
import { IconButton, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { ReactComponent as CloseIcon } from "assets/x-close.svg";
import { ReactComponent as ChatbotGradientIcon } from "assets/icons/chatbot-gradient-icon.svg";
import { ReactComponent as VoicebotGradientIcon } from "assets/icons/voicebot-gradient-icon.svg";
import { ReactComponent as ArrowRight } from "assets/arrow-right.svg";
import { useSelectBotModalStyles as useStyles } from "./style";
import Button, { ButtonSizes } from "aether/Button";
import { useSnackbar } from "notistack";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";
import { createAssistant } from "api/assistant/assistant";
import { AssistantType } from "pages/Assistant/types";

const modal_title = "Select what kind of assistant you would like to build";
const chatbot_text = {
  title: "Chat Assistant",
  subtitle:
    "Build an AI-powered assistant to manage complex workflows, automate tasks, and handle advanced use cases with copilot support.",
};
const voicebot_text = {
  title: "Voice Assistant",
  subtitle:
    "Create a simple AI-powered voice assistant using natural language to automate calls and manage straightforward tasks.",
};
const name_input = {
  label: "Name",
  [AssistantType.Chat]: "E.g. Travel operations chat assistant",
  [AssistantType.Voice]: "E.g. Travel operations voice assistant",
};
const description_input = {
  label: "Description",
  [AssistantType.Chat]: "Eg. Helps customer know flight details",
  [AssistantType.Voice]: "Eg. Helps customer know flight details",
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const SelectAssistantModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { gotoAssistant } = useRouteNavigator();

  const [assistantType, setAssistantType] = useState<AssistantType>(
    AssistantType.Chat
  );
  const [loading, setLoading] = useState(false);
  const [botFields, setBotFields] = useState({
    name: "",
    description: "",
  });

  const handleSelectBotType = (type: AssistantType): void => {
    setAssistantType(type);
  };

  const resetBotFields = useCallback(() => {
    setBotFields({ name: "", description: "" });
  }, []);

  const handleCreateAssistant = useCallback(async () => {
    setLoading(true);

    try {
      const response = await createAssistant({
        name: botFields.name,
        description: botFields.description,
        enabled_channels: [assistantType],
      });
      const { data } = response;
      const { assistant } = data;

      enqueueSnackbar("Assistant created", {
        variant: "success",
      });
      resetBotFields();

      gotoAssistant(assistant._id);
    } catch (err: any) {
      enqueueSnackbar(`Some error occurred. Please try again ${err}`, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [
    botFields.name,
    botFields.description,
    assistantType,
    enqueueSnackbar,
    resetBotFields,
    gotoAssistant,
  ]);

  return (
    <Dialog
      className={classes.createAssistantModal}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <div className={classes.createAssistantContent}>
        <div className="flex justify-content-between align-items-center">
          <Typography
            variant={TypographyVariants.textXL}
            weight={TypographyWeights.semiBold}
          >
            {modal_title}
          </Typography>
          <IconButton className="close-create-bot-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="select-bot-type-container flex col-gap-24">
          <div
            className={clsx("select-container select-chatbot cursor-pointer", {
              selected: assistantType === AssistantType.Chat,
            })}
            onClick={() => {
              handleSelectBotType(AssistantType.Chat);
            }}
          >
            <div className="chatbot-icon-container">
              <ChatbotGradientIcon />
            </div>
            <Typography
              variant={TypographyVariants.textLarge}
              weight={TypographyWeights.semiBold}
            >
              {chatbot_text.title}
            </Typography>
            <Typography
              variant={TypographyVariants.textSmall}
              weight={TypographyWeights.medium}
              className="text-center"
            >
              {chatbot_text.subtitle}
            </Typography>
          </div>

          <div
            className={clsx("select-container select-voicebot cursor-pointer", {
              selected: assistantType === AssistantType.Voice,
            })}
            onClick={() => {
              handleSelectBotType(AssistantType.Voice);
            }}
          >
            <div className="voicebot-icon-container">
              <VoicebotGradientIcon />
            </div>
            <Typography
              variant={TypographyVariants.textLarge}
              weight={TypographyWeights.bold}
            >
              {voicebot_text.title}
            </Typography>
            <Typography
              variant={TypographyVariants.textSmall}
              weight={TypographyWeights.medium}
              className="text-center"
            >
              {voicebot_text.subtitle}
            </Typography>
          </div>
        </div>

        {assistantType && (
          <div className="flex flex-col row-gap-16">
            <div className="flex flex-col row-gap-4">
              <Typography weight={TypographyWeights.semiBold}>
                {name_input.label}
              </Typography>
              <TextField
                name="botName"
                value={botFields.name}
                variant="outlined"
                size="small"
                placeholder={name_input[assistantType]}
                fullWidth
                onChange={(event) => {
                  setBotFields((state) => ({
                    ...state,
                    name: event.target.value,
                  }));
                }}
              />
            </div>

            <div className="flex flex-col row-gap-4">
              <Typography weight={TypographyWeights.semiBold}>
                {description_input.label}
              </Typography>
              <TextField
                name="botDescription"
                value={botFields.description}
                variant="outlined"
                size="small"
                placeholder={description_input[assistantType]}
                fullWidth
                onChange={(event) => {
                  setBotFields((state) => ({
                    ...state,
                    description: event.target.value,
                  }));
                }}
              />
            </div>

            <Button
              size={ButtonSizes.small}
              className="align-self-start"
              endIcon={<ArrowRight />}
              loading={loading}
              disabled={!botFields.name}
              onClick={() => {
                void handleCreateAssistant();
              }}
            >
              Proceed
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default SelectAssistantModal;
