import type React from "react";
import { type ActionTileConfig } from "./AgentActions.const";
import useStyles from "./ActionTile.style";
import { IconButton as MUIIconButton } from "@mui/material";
import { ReactComponent as EllipseIcon } from "assets/Ellipse 703.svg";
import IconButton from "aether/IconButton";
import clsx from "clsx";

interface Props {
  tileConfig: ActionTileConfig;
  onClick: () => void;
  selected?: boolean;
}

const ActionTile: React.FC<Props> = ({
  tileConfig,
  onClick,
  selected = false,
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.container, {
        [classes.selectedContainer]: selected,
      })}
      onClick={onClick}
    >
      <div className={classes.content}>
        <div className={classes.title}>{tileConfig.name}</div>
        <div className={classes.description}>{tileConfig.description}</div>
        <div className={classes.infoKeys}>
          {tileConfig.actionCount && (
            <div
              className={classes.info}
            >{`${tileConfig.actionCount} ${tileConfig.actionCountLabel}`}</div>
          )}
          {tileConfig.subActionCount && (
            <>
              <MUIIconButton>
                <EllipseIcon />
              </MUIIconButton>

              <div className={classes.info}>
                {`${tileConfig.subActionCount} ${tileConfig.subActionLabel}`}
              </div>
            </>
          )}
        </div>
      </div>
      <IconButton variant="contained" size="medium" color="tertiary">
        {tileConfig.icon}
      </IconButton>
    </div>
  );
};

export default ActionTile;
