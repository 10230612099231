import { AssistantRoute, IAssistantNavigation } from "./types";
import { ReactComponent as AssistantOverviewIcon } from "assets/assistant-overview-icon.svg";
import { ReactComponent as AssistantKnowledgeIcon } from "assets/assistant-knowledge-icon.svg";
import { ReactComponent as AssistantAgentsIcon } from "assets/assistant-agents-icon.svg";
import { ReactComponent as AssistantToolsIcon } from "assets/assistant-tools-icon.svg";
import { ReactComponent as AssistantDeploymentIcon } from "assets/assistant-deployment-icon.svg";

export const AssistantNavigations: IAssistantNavigation[] = [
  {
    label: "Overview",
    key: AssistantRoute.OVERVIEW,
    icon: <AssistantOverviewIcon />,
  },
  {
    label: "Knowledge",
    key: AssistantRoute.KNOWLEDGE,
    icon: <AssistantKnowledgeIcon />,
  },
  {
    label: "Agents",
    key: AssistantRoute.AGENTS,
    icon: <AssistantAgentsIcon />,
  },
  {
    label: "Tools",
    key: AssistantRoute.TOOLS,
    icon: <AssistantToolsIcon />,
  },
  {
    label: "Deployment",
    key: AssistantRoute.DEPLOYMENT,
    icon: <AssistantDeploymentIcon />,
  },
];
