import { createContext } from "react";
import { Agent } from "./types";
import { IAssistant } from "pages/Assistant/types";
import { defaultAssistant } from "pages/Assistant/AssistantContext";

export const defaultAgent: Agent = {
  _id: "",
  created: "",
  modified: "",
  name: "",
  description: "",
  knowledge_base: null,
  kb_metadata: {
    crawl_resources_count: 0,
    knowledge_units_count: 0,
  },
  company_info_text: null,
  goal: "",
  guidelines_and_rules: "",
  begin_message_config: {
    user_first: false,
    static_message: null,
    ai_prompt: null,
  },
  trigger: "",
  general_prompt: "",
  tenant: "",
  bot_id: "",
};

export interface AgentBuilderContextProps {
  assistantId: string;
  agentId: string;
  bot: IAssistant;
  agent: Agent;
}

const AgentBuilderContext = createContext<AgentBuilderContextProps>({
  assistantId: "",
  agentId: "",
  bot: defaultAssistant,
  agent: defaultAgent,
});
export default AgentBuilderContext;
