import AccordionCard from "components/base/AccordionCard";
import { ReactComponent as AssistantGuidelinesIcon } from "assets/guidelines-rule-icon.svg";
import AssistantGuidelinesAndRules from "./AssistantGuidelinesAndRules";
import { useAssistantGuidelinesStyles as useStyles } from "./styles";

const guidelines_title = "Guidelines and Rules";
const guidelines_subtitle =
  "Define the assistant’s behavior and how it should interact with your customers";

const GuidelinesAndRules: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.assistantGuidelinesContainer}>
      <AccordionCard
        defaultExpanded={true}
        title={guidelines_title}
        subtitle={guidelines_subtitle}
        icon={<AssistantGuidelinesIcon />}
        content={<AssistantGuidelinesAndRules />}
      />
    </div>
  );
};

export default GuidelinesAndRules;
