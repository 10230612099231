import { type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    cursor: "pointer",
    flex: "0 0 calc(50% - 8px)",
    width: "50%",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid var(--border-default, #E1DEDA)",
    background: "#FFF",
    overflow: "hidden",
  },
  selectedContainer: { border: "1px solid var(--border-active, #F07400)" },
  content: {
    overflow: "hidden",
    marginRight: "16px",
  },
  title: {
    color: "var(--fg-text-default, #282624)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  description: {
    color: "var(--text-default, #282624)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
  },
  icon: {},
  infoKeys: {
    display: "flex",
    marginTop: "8px",
  },
  info: {
    color: "var(--text-subtle, #7C7972)",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "20px",
  },
}));

export default useStyles;
