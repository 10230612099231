import { useCallback, useState } from "react";
import ColoredSwitch from "components/base/ColoredSwitch";
import CenteredLoader from "components/shared/CenteredLoader";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { patchLinkSource, patchFileSource } from "api/knowledge-base/source";

interface ToggleSourceProps {
  action: "file" | "link";
  enabled: boolean;
  id: string;
  onChangeVisibility: (id: string, enabled: boolean) => void;
}

const SourceVisibility: React.FC<ToggleSourceProps> = ({
  action,
  enabled,
  id,
  onChangeVisibility,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [showLoader, setShowLoader] = useState(false);

  const alterSourceStatus = useCallback(async () => {
    if (action === "link") {
      await patchLinkSource(id, { enabled: !enabled })
        .then((response) => {
          onChangeVisibility(id, response.enabled);
          enqueueSnackbar(
            `Source ${response.name} ${
              response.enabled ? "enabled" : "disabled"
            }`,
            {
              variant: "success",
            }
          );
        })
        .catch(() => {
          enqueueSnackbar("Some error occurred. Please try again", {
            variant: "error",
          });
        })
        .finally(() => {
          setShowLoader(false);
        });
    } else if (action === "file") {
      await patchFileSource(id, { active: !enabled })
        .then((response) => {
          onChangeVisibility(id, response.active);

          enqueueSnackbar(
            `Source ${response.active ? "enabled" : "disabled"}`,
            {
              variant: "success",
            }
          );
        })
        .catch(() => {
          enqueueSnackbar("Some error occurred. Please try again", {
            variant: "error",
          });
        })
        .finally(() => {
          setShowLoader(false);
        });
    }
  }, [action, dispatch, enabled, enqueueSnackbar, id]);

  const handleClickToggle = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      setShowLoader(true);

      await alterSourceStatus();
    },
    [alterSourceStatus]
  );

  return (
    <>
      {showLoader ? (
        <Box display="flex" justifyContent="start">
          <CenteredLoader />
        </Box>
      ) : (
        <ColoredSwitch
          checked={enabled}
          onClick={(e) => {
            void handleClickToggle(e);
          }}
        />
      )}
    </>
  );
};

export default SourceVisibility;
