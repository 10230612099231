import TextField from "@mui/material/TextField";
import Typography from "components/base/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { type FormikProps, useFormikContext } from "formik";
import {
  AppearanceSettingsFields,
  RadioValueType,
  type IAppearanceSettings,
} from "pages/Assistant/types";
import { useChatCollapsedSettingsStyles as useStyles } from "./styles";
import { useCallback, useContext, useRef } from "react";
import { patchAssistantChatbotSettings } from "api/assistant/deployment";
import { useSnackbar } from "notistack";
import AssistantContext from "pages/Assistant/AssistantContext";
import { useDispatch } from "store";
import { SettingsActions } from "store/reduxActions/botActions";

interface ChatCollapsedInputFields {
  [AppearanceSettingsFields.nudge_message]: string;
}

interface ChatCollapsedRadioFields {
  [AppearanceSettingsFields.display_nudge]: RadioValueType;
  [AppearanceSettingsFields.default_widget_state]: RadioValueType;
}

const ChatCollapsedSettings: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const assistantContext = useContext(AssistantContext);
  const { assistantId } = assistantContext || {};

  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    errors,
  }: FormikProps<IAppearanceSettings> = useFormikContext();
  const { nudge_message, display_nudge, default_widget_state } = values ?? {};

  const currentChatCollapsedInputFields = useRef<ChatCollapsedInputFields>({
    nudge_message,
  });
  const currentChatCollapsedRadioFields = useRef<ChatCollapsedRadioFields>({
    display_nudge,
    default_widget_state,
  });

  const handleOnBlur = useCallback(
    async (event: any, field: keyof ChatCollapsedInputFields) => {
      handleBlur(event);

      if (currentChatCollapsedInputFields.current[field] === values[field]) {
        return;
      }

      try {
        const response = await patchAssistantChatbotSettings(assistantId, {
          [field]: values[field],
        });
        const { data } = response;
        const { settings } = data;
        const { chatbot_settings } = settings;

        dispatch({
          type: SettingsActions.UPDATE_CHATBOT_SETTINGS,
          payload: { ...chatbot_settings },
        });

        currentChatCollapsedInputFields.current[field] = values[field];
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving details ${error}`, {
          variant: "error",
        });
        void setFieldValue(
          field,
          currentChatCollapsedInputFields.current[field]
        );
      }
    },
    [assistantId, enqueueSnackbar, handleBlur, setFieldValue, values, dispatch]
  );

  const handleOnRadioChange = useCallback(
    async (event: any, field: keyof ChatCollapsedRadioFields) => {
      handleChange(event);

      const updatedValue = event.target.value;

      if (currentChatCollapsedRadioFields.current[field] === updatedValue) {
        return;
      }

      try {
        const response = await patchAssistantChatbotSettings(assistantId, {
          [field]: updatedValue,
        });

        const { data } = response;
        const { settings } = data;
        const { chatbot_settings } = settings;

        dispatch({
          type: SettingsActions.UPDATE_CHATBOT_SETTINGS,
          payload: { ...chatbot_settings },
        });

        currentChatCollapsedRadioFields.current[field] = updatedValue;
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving details ${error}`, {
          variant: "error",
        });
        void setFieldValue(
          field,
          currentChatCollapsedRadioFields.current[field]
        );
      }
    },
    [assistantId, enqueueSnackbar, handleChange, setFieldValue, dispatch]
  );

  return (
    <div className={classes["chat-collapsed-settings-container"]}>
      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">
            Display a nudge when widget is closed
          </Typography>
          <RadioGroup
            name={AppearanceSettingsFields.display_nudge}
            className="px-1 row-gap-8"
            value={values?.display_nudge}
            onChange={(event) => {
              void handleOnRadioChange(
                event,
                AppearanceSettingsFields.display_nudge
              );
            }}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </div>

        <div className="form-input-container">
          <Typography className="w-semi-bold">
            The default state of widget
          </Typography>
          <RadioGroup
            name={AppearanceSettingsFields.default_widget_state}
            className="px-1 row-gap-8"
            value={values?.default_widget_state}
            onChange={(event) => {
              void handleOnRadioChange(
                event,
                AppearanceSettingsFields.default_widget_state
              );
            }}
          >
            <FormControlLabel
              value="closed"
              control={<Radio />}
              label="Closed"
            />
            <FormControlLabel value="open" control={<Radio />} label="Open" />
          </RadioGroup>
        </div>
      </div>

      <div className="settings-row flex justify-content-between">
        <div className="form-input-container">
          <Typography className="w-semi-bold">Nudge message</Typography>
          <TextField
            name={AppearanceSettingsFields.nudge_message}
            variant="outlined"
            size="small"
            placeholder="Hi! I can help you find answers"
            fullWidth
            multiline
            minRows={2}
            maxRows={2}
            value={values?.nudge_message}
            onChange={handleChange}
            onBlur={(event) => {
              void handleOnBlur(event, AppearanceSettingsFields.nudge_message);
            }}
            error={touched.nudge_message && Boolean(errors.nudge_message)}
            helperText={touched.nudge_message && errors.nudge_message}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatCollapsedSettings;
