import AccordionCard from "components/base/AccordionCard";
import { ReactComponent as AssistantAgentsIcon } from "assets/assistant-agents-icon.svg";
import { useAssistantAgentsStyles as useStyles } from "./styles";
import AssistantAgents from "./AssistantAgents";
import CreateAgentModal from "./CreateAgentModal";
import { useContext, useEffect, useState } from "react";
import AssistantContext from "../AssistantContext";
import { TableStateProps } from "aether/TableState";
import { getAllAgents } from "api/assistant/agents";
import Button, { ButtonSizes, ButtonVariants } from "aether/Button";

const agents_title = "Agents";
const agents_subtitle =
  "Build child assistants focused within your assistant to handle specific tasks powdered by actions";

export interface AssistantAgentsData {
  _id: string;
  icon: React.ReactNode;
  name: string;
  description: string;
  editedOn: string;
  status: string;
  actions: string;
}

const Agents: React.FC = () => {
  const classes = useStyles();

  const assistantContext = useContext(AssistantContext);
  const { assistantId, assistant } = assistantContext;

  const [tableData, setTableData] = useState<AssistantAgentsData[]>([]);
  const [tableState, setTableState] = useState<TableStateProps>({
    loading: false,
    empty: false,
    error: false,
  });
  const [openCreateAgentModal, setOpenCreateAgentModal] = useState(false);

  useEffect(() => {
    setTableState((state) => ({ ...state, loading: true }));
    getAllAgents(assistantId)
      .then((response) => {
        if (response?.length) {
          setTableData(response);
        } else {
          setTableState((state) => ({ ...state, empty: true }));
        }
      })
      .catch((error) => {
        console.log(error);
        setTableState((state) => ({
          ...state,
          error: true,
        }));
      })
      .finally(() => {
        setTableState((state) => ({ ...state, loading: false }));
      });
  }, []);

  return (
    <div className={classes.assistantAgentsContainer}>
      <AccordionCard
        defaultExpanded={true}
        title={agents_title}
        subtitle={agents_subtitle}
        icon={<AssistantAgentsIcon />}
        content={
          <AssistantAgents
            tableData={tableData}
            tableState={tableState}
            onUpdateTableData={(tableData) => {
              setTableData(tableData);
            }}
            onUpdateTableState={(tableState) => {
              setTableState(tableState);
            }}
            handleOpenCreateAgentModal={() => {
              setOpenCreateAgentModal(true);
            }}
          />
        }
        headerButtons={
          <Button
            variant={ButtonVariants.outlined}
            size={ButtonSizes.xsmall}
            onClick={() => {
              setOpenCreateAgentModal(true);
            }}
          >
            Add a new agent
          </Button>
        }
        contentStyle={{
          backgroundColor: "#FFF8F2",
        }}
      />
      <CreateAgentModal
        assistant={assistant}
        open={openCreateAgentModal}
        onClose={() => {
          setOpenCreateAgentModal(false);
        }}
      />
    </div>
  );
};

export default Agents;
