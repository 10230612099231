import Modal from "components/base/Modal/Modal";
import { useEffect, useState } from "react";
import { ReactComponent as FilePlusIcon } from "assets/file-plus-icon.svg";
import { getKnowledgeSources } from "api/knowledge-base/source";
import { FileResource, LinkResource } from "pages/KnowledgeBase/types";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import SourceTable from "./SourceTable";
import { useKnowledgeSourceStyles as useStyles } from "./style";

interface Props {
  resources: Array<FileResource | LinkResource>;
  open: boolean;
  saving: boolean;
  onClose: () => void;
  onConfirm: (selectedSources: string[]) => void;
  onCancel: () => void;
}

const SelectKnowledgeSource: React.FC<Props> = ({
  resources,
  open,
  saving,
  onClose,
  onConfirm,
  onCancel,
}) => {
  const classes = useStyles();

  const [parentNode, setParentNode] = useState<HTMLDivElement | null>();
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>();
  const [tableState, setTableState] = useState({
    loading: true,
    empty: false,
    error: false,
  });
  const [selectedSource, setSelectedSource] = useState<string[]>([]);

  useEffect(() => {
    if (resources.length > 0) {
      const resourceIds = resources.map((resource) => resource.resourceId);
      setSelectedSource(resourceIds);
    }
  }, [resources]);

  const onDataFetched = ({
    count,
    results,
  }: {
    count: number;
    results: Array<FileResource | LinkResource>;
  }): void => {
    if (!count) {
      setTableState({ loading: false, empty: true, error: false });
    }

    setTableState({ loading: false, empty: false, error: false });
  };

  const onError = (err: any): void => {
    console.error(err);
    setTableState({ loading: false, empty: false, error: true });
  };

  const {
    loading: pageLoading,
    fetchedData: tableData,
    nextUrl,
  } = useInfiniteScroll({
    parentNode,
    lastElement,
    apiConfig: {
      getData: getKnowledgeSources,
    },
    beforeStartFetchingData: () => {},
    onDataFetched,
    onError,
    filters: {},
  });

  const handleSelectAllSource = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.checked) {
      const newSelected = tableData.map(
        (n: FileResource | LinkResource) => n.resourceId
      );
      setSelectedSource(newSelected);
      return;
    }
    setSelectedSource([]);
  };

  const handleSelectSource = (
    event: React.ChangeEvent<HTMLInputElement>,
    resourceId: string
  ): void => {
    const selectedIndex = selectedSource.indexOf(resourceId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSource, resourceId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedSource.slice(1));
    } else if (selectedIndex === selectedSource.length - 1) {
      newSelected = newSelected.concat(selectedSource.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedSource.slice(0, selectedIndex),
        selectedSource.slice(selectedIndex + 1)
      );
    }
    setSelectedSource(newSelected);
  };

  const handleOnConfirm = (): void => {
    onConfirm(selectedSource.map((resourceId) => resourceId.toString()));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={"Select knowledge"}
      confirmText="Add knowledge"
      confirmStartIcon={<FilePlusIcon />}
      showConfirmLoading={saving}
      onConfirm={handleOnConfirm}
      onCancel={onCancel}
      maxWidth="lg"
    >
      <div className={classes.sourceListContainer}>
        <SourceTable
          data={tableData}
          tableState={tableState}
          pageLoading={pageLoading}
          nextUrl={nextUrl}
          selectState={{
            selected: selectedSource,
            onSelect: handleSelectSource,
            onSelectAll: handleSelectAllSource,
          }}
          setParentNode={setParentNode}
          setLastElement={setLastElement}
        />
      </div>
    </Modal>
  );
};

export default SelectKnowledgeSource;
