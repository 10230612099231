import { useContext } from "react";
import { useAssistantAgentsStyles as useStyles } from "./styles";
import AssistantContext from "../AssistantContext";
import { type TableStateProps } from "aether/TableState";
import Table, { type TableColumnProps } from "aether/Table";
import Typography, {
  TypographyVariants,
  TypographyWeights,
} from "aether/Typography";
import { formatToHumanStringFromDateTime } from "utils/dateUtils";
import Chip, { ChipColors, ChipSizes } from "aether/Chip";
import DeleteAgentModal from "./DeleteAgentModal";
import IconButton from "aether/IconButton";
import { ReactComponent as ArrowRight } from "assets/arrow-right.svg";
import useRouteNavigator from "hooks/navigation/useRouteNavigator";
import Button, { ButtonSizes } from "aether/Button";
import { AssistantAgentsData } from ".";

interface Props {
  tableData: AssistantAgentsData[];
  tableState: TableStateProps;
  onUpdateTableData: (tableData: AssistantAgentsData[]) => void;
  onUpdateTableState: (tableState: TableStateProps) => void;
  handleOpenCreateAgentModal: () => void;
}

const AssistantAgents: React.FC<Props> = ({
  tableData,
  tableState,
  onUpdateTableData,
  onUpdateTableState,
  handleOpenCreateAgentModal,
}) => {
  const classes = useStyles();
  const { gotoAgentBuilder } = useRouteNavigator();

  const assistantContext = useContext(AssistantContext);
  const { assistantId } = assistantContext;

  const handleOnDeleteAgentCb = (agentId: string): void => {
    const updatedAgents = tableData.filter(
      (agent: AssistantAgentsData) => agent._id !== agentId
    );
    onUpdateTableData(updatedAgents);
  };

  const handleGotoAgentBuilder = (event: any, agentId: string): void => {
    gotoAgentBuilder(assistantId, agentId);
  };

  const AgentsTable = Table<AssistantAgentsData>();
  const agentsColumns: Array<TableColumnProps<AssistantAgentsData>> = [
    {
      id: "name",
      label: "Agent Name",
      width: "40%",
      render: (row: AssistantAgentsData) => (
        <div className={"agent-name-container"}>
          <div className="flex flex-col">
            <Typography
              className="agent-name"
              weight={TypographyWeights.semiBold}
              onClick={(event) => {
                handleGotoAgentBuilder(event, row._id);
              }}
            >
              {row.name}
            </Typography>
            <Typography variant={TypographyVariants.textSmall}>
              {row.description}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      id: "editedOn",
      label: "Last edited",
      width: "20%",
      render: (row: AssistantAgentsData) => (
        <Typography>
          {formatToHumanStringFromDateTime(
            new Date(row?.editedOn),
            "MMM d, yyyy"
          )}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "Status",
      width: "20%",
      render: (row: AssistantAgentsData) => (
        <Chip
          label={"Active"}
          color={ChipColors.success}
          size={ChipSizes.large}
        />
      ),
    },
    {
      id: "actions",
      label: "",
      width: "20%",
      align: "right",
      render: (row: AssistantAgentsData) => (
        <div className={"row-action-container"}>
          <DeleteAgentModal
            agentId={row._id}
            onDeleteCb={handleOnDeleteAgentCb}
          />
          <IconButton
            size="xsmall"
            variant="contained"
            color="primary"
            onClick={(event) => {
              handleGotoAgentBuilder(event, row._id);
            }}
          >
            <ArrowRight />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className={classes.assistantAgentsContainer}>
      <AgentsTable
        columns={agentsColumns}
        data={tableData}
        loading={tableState.loading}
        emptyState={{
          empty: tableState.empty,
          message: "No skills here yet. Add your first one!",
          button: (
            <Button
              size={ButtonSizes.xsmall}
              onClick={handleOpenCreateAgentModal}
            >
              Add a new agent
            </Button>
          ),
        }}
        error={tableState.error}
      />
    </div>
  );
};

export default AssistantAgents;
