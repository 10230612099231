import AccordionCard from "components/base/AccordionCard";
import { ReactComponent as AgentKnowledgeIcon } from "assets/knowledge-icon.svg";
import KnowledgeSource from "./KnowledgeSource";
import { useAssistantKnowlegdeStyles as useStyles } from "./styles";
import Button, { ButtonSizes, ButtonVariants } from "aether/Button";
import { ReactComponent as FilePlusIcon } from "assets/file-plus-icon.svg";
import { ReactComponent as KnowledgeBaseIcon } from "assets/knowledge-base-icon.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/external-link-icon.svg";
import { useContext, useState } from "react";
import { FileResource, LinkResource } from "pages/KnowledgeBase/types";
import AgentBuilderContext from "../AgentBuilderContext";
import { patchAgent } from "api/assistant/agents";
import SelectKnowledgeSource from "components/KnowledgeSource";
import { formatResources } from "pages/Assistant/utils";

const knowledge_title = "Knowledge";
const knowledge_subtitle =
  "Equip the agent with knowledge, defining what your it knows";

const Knowledge: React.FC = () => {
  const classes = useStyles();

  const agentBuilderContext = useContext(AgentBuilderContext);
  const { agentId, agent } = agentBuilderContext;

  const { kb_metadata, knowledge_base } = agent;
  const { crawl_resources_count, knowledge_units_count } = kb_metadata;
  const { resources = [] } = knowledge_base ?? {};

  const [openSelectSource, setOpenSelectSource] = useState(false);
  const [agentResources, setAgentResources] = useState<
    Array<FileResource | LinkResource>
  >(formatResources(resources));
  const [sourceAdding, setSourceAdding] = useState(false);

  const handleClickOnAddKnowledge = (selectedSource: string[]): void => {
    const data = {
      _id: agentId,
      knowledge_base: {
        resource_ids: selectedSource,
      },
    };
    setSourceAdding(true);
    patchAgent(data)
      .then((response) => {
        if (response?.knowledge_base) {
          const _resources = response.knowledge_base.resources;
          setAgentResources(_resources);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSourceAdding(false);
        setOpenSelectSource(false);
      });
  };

  const handleOnUpdateResources = (
    resources: Array<FileResource | LinkResource>
  ): void => {
    setAgentResources(resources);
  };

  return (
    <div className={classes.assistantKnowledgeContainer}>
      <AccordionCard
        defaultExpanded={true}
        title={knowledge_title}
        subtitle={knowledge_subtitle}
        icon={<AgentKnowledgeIcon />}
        content={
          <KnowledgeSource
            resources={agentResources}
            handleOpenSelectSource={() => {
              setOpenSelectSource(true);
            }}
            onUpdateResources={handleOnUpdateResources}
          />
        }
        headerButtons={
          <div className="flex col-gap-16">
            <Button
              variant={ButtonVariants.outlined}
              size={ButtonSizes.xsmall}
              onClick={() => {
                window.open(`/knowledge-base`, "_blank");
              }}
              startIcon={<KnowledgeBaseIcon />}
              endIcon={<ExternalLinkIcon />}
            >
              View knowledge base
            </Button>
            <Button
              variant={ButtonVariants.outlined}
              size={ButtonSizes.xsmall}
              onClick={() => {
                setOpenSelectSource(true);
              }}
              startIcon={<FilePlusIcon />}
              disabled={
                !(crawl_resources_count > 0 || knowledge_units_count > 0)
              }
            >
              Add knowledge
            </Button>
          </div>
        }
      />
      <SelectKnowledgeSource
        resources={agentResources}
        open={openSelectSource}
        onClose={() => {
          setOpenSelectSource(false);
        }}
        saving={sourceAdding}
        onConfirm={handleClickOnAddKnowledge}
        onCancel={() => {
          setOpenSelectSource(false);
        }}
      />
    </div>
  );
};

export default Knowledge;
