import AccordionCard from "components/base/AccordionCard";
import { ReactComponent as AssistantKnowledgeIcon } from "assets/assistant-knowledge-icon.svg";
import KnowledgeSource from "./KnowledgeSource";
import { useAssistantKnowlegdeStyles as useStyles } from "./styles";
import Button, { ButtonSizes, ButtonVariants } from "aether/Button";
import { ReactComponent as FilePlusIcon } from "assets/file-plus-icon.svg";
import { ReactComponent as KnowledgeBaseIcon } from "assets/knowledge-base-icon.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/external-link-icon.svg";
import { useContext, useState } from "react";
import AssistantContext from "../AssistantContext";
import { patchAssistant } from "api/assistant/assistant";
import { FileResource, LinkResource } from "pages/KnowledgeBase/types";
import SelectKnowledgeSource from "components/KnowledgeSource";
import { formatResources } from "../utils";

const knowledge_title = "Knowledge";
const knowledge_subtitle =
  "Equip the assistant with knowledge, defining what your it knows";

const Knowledge: React.FC = () => {
  const classes = useStyles();

  const assistantContext = useContext(AssistantContext);
  const { assistantId, assistant } = assistantContext;

  const { kb_metadata, knowledge_base } = assistant;
  const { crawl_resources_count, knowledge_units_count } = kb_metadata;
  const { resources = [] } = knowledge_base ?? {};

  const [saving, setSaving] = useState(false);
  const [openSelectSource, setOpenSelectSource] = useState(false);
  const [assistantResources, setAssistantResources] = useState<
    Array<FileResource | LinkResource>
  >(formatResources(resources));

  const handleClickOnAddKnowledge = (selectedSource: string[]): void => {
    const data = {
      knowledge_base: {
        resource_ids: selectedSource,
      },
    };

    setSaving(true);

    patchAssistant(assistantId, data)
      .then((response) => {
        if (response.data.assistant?.knowledge_base) {
          const _resources = formatResources(
            response.data.assistant.knowledge_base.resources
          );
          setAssistantResources(_resources);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSaving(false);
        setOpenSelectSource(false);
      });
  };

  const handleOnUpdateResources = (
    resources: Array<FileResource | LinkResource>
  ): void => {
    setAssistantResources(resources);
  };

  return (
    <div className={classes.assistantKnowledgeContainer}>
      <AccordionCard
        defaultExpanded={true}
        title={knowledge_title}
        subtitle={knowledge_subtitle}
        icon={<AssistantKnowledgeIcon />}
        content={
          <KnowledgeSource
            resources={assistantResources}
            handleOpenSelectSource={() => {
              setOpenSelectSource(true);
            }}
            onUpdateResources={handleOnUpdateResources}
          />
        }
        headerButtons={
          <div className="flex col-gap-16">
            <Button
              variant={ButtonVariants.outlined}
              size={ButtonSizes.xsmall}
              onClick={() => {
                window.open(`/knowledge-base`, "_blank");
              }}
              startIcon={<KnowledgeBaseIcon />}
              endIcon={<ExternalLinkIcon />}
            >
              View knowledge base
            </Button>
            <Button
              variant={ButtonVariants.outlined}
              size={ButtonSizes.xsmall}
              onClick={() => {
                setOpenSelectSource(true);
              }}
              startIcon={<FilePlusIcon />}
              disabled={
                !(crawl_resources_count > 0 || knowledge_units_count > 0)
              }
            >
              Add knowledge
            </Button>
          </div>
        }
        contentStyle={{
          backgroundColor: "#FFF8F2",
        }}
      />
      <SelectKnowledgeSource
        resources={assistantResources}
        open={openSelectSource}
        onClose={() => {
          setOpenSelectSource(false);
        }}
        saving={saving}
        onConfirm={handleClickOnAddKnowledge}
        onCancel={() => {
          setOpenSelectSource(false);
        }}
      />
    </div>
  );
};

export default Knowledge;
