import { TextField } from "@mui/material";
import Typography, { TypographyWeights } from "aether/Typography";
import { type IAgentComposition } from "./types";
import { useFormikContext } from "formik";
import { useCallback, useContext, useRef } from "react";
import { patchAgent } from "api/bot/bot-agents";
import AgentBuilderContext from "../AgentBuilderContext";
import { useSnackbar } from "notistack";

const trigger_scope_placeholder =
  "E.g. AI Travel Assistant responsible for handling flight-related bookings, answering flight inquiries, managing cancellations, and providing real-time updates, ensuring seamless customer experiences for an online travel company.";

const TriggerAndScope: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const agentBuilderContext = useContext(AgentBuilderContext);
  const { agentId, agent } = agentBuilderContext;

  const { values, handleBlur, handleChange } =
    useFormikContext<IAgentComposition>();

  const currentTriggerScope = useRef<string>(values.trigger);

  const handleOnBlur = useCallback(
    async (event: any): Promise<any> => {
      handleBlur(event);

      if (!values.trigger || values.trigger === currentTriggerScope.current) {
        return;
      }

      try {
        await patchAgent({
          _id: agentId,
          trigger: values.trigger,
        });

        currentTriggerScope.current = values.trigger;
      } catch (error: any) {
        enqueueSnackbar(`Some error occurred while saving script ${error}`, {
          variant: "error",
        });
      }
    },
    [values.trigger]
  );

  return (
    <div className="flex flex-col row-gap-8">
      <Typography weight={TypographyWeights.semiBold}>
        {`Describe when should '${agent?.name}' should get activated.`}
      </Typography>
      <TextField
        name="trigger"
        variant="outlined"
        size="small"
        placeholder={trigger_scope_placeholder}
        fullWidth
        value={values.trigger}
        onChange={handleChange}
        onBlur={(event) => {
          void handleOnBlur(event);
        }}
        minRows={3}
        maxRows={10}
        multiline
      />
    </div>
  );
};

export default TriggerAndScope;
